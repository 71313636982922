import React from "react";
import TopBanner from "../../../Shared/ServicePageTopBanner/TopBanner";
import RtoLVector from "../../../UI-Style/RtoLVector";
import StepsCard from "../../../Shared/StepsCard/StepsCard";
import LtoR from "../../../UI-Style/LtoRVector";
import Video from "../../../Shared/Video/Video";
import ServicePricingTable from "../../../Shared/PricingTable/ServicePricingTable";
import { useTranslation } from "react-i18next";
import Contact from "../../Home/Contact/Contact";
import { Helmet } from "react-helmet-async";
const SocialMediaMarketing = () => {
  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";
  const pricingData = t("pricing.PricingTable.pricingDataSMM", {
    returnObjects: true,
  });
  const topBannerData = t("social_media_marketing_page.topBannerData", {
    returnObjects: true,
  });
  const smmSteps = t("social_media_marketing_page.smmSteps", {
    returnObjects: true,
  });

  return (
    <div>
      <Helmet>
        <title>Social Media Marketing Services in Kuwait | Lucid</title>
        <meta
          name="description"
          content="Grow your online presence with Lucid's social media marketing services in Kuwait. We help businesses connect with their audience through targeted social media campaigns."
        />
        <link
          rel="canonical"
          href="https://lucid.com.kw/service/social-media-marketing"
        />
      </Helmet>

      <TopBanner data={topBannerData} />
      <RtoLVector />
      <StepsCard stepsData={smmSteps} />
      <LtoR />
      <Video />
      <RtoLVector />
      <ServicePricingTable pricingData={pricingData} />
      <LtoR />
      <Contact />
    </div>
  );
};

export default SocialMediaMarketing;
