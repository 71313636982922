import React from "react";
import "./HeroSection.css";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const { t, ready } = useTranslation();
  if (!ready) return "loading translations...";

  // const contactInfo = t("footer.contactInfo", { returnObjects: true });

  const motionSettings = {
    initial: { x: -100, opacity: 0 },
    whileInView: { x: 0, opacity: 1 },
    transition: {
      delay: 0.2,
      opacity: { duration: 1 },
      ease: "easeIn",
      duration: 1,
    },
  };
  return (
    <section className="solution-hero-section">
      <div className="container ">
        <div className="row  gx-5">
          <div className="col-md-6 col-12 col-lg-6">
            <motion.img
              {...motionSettings}
              className="hero-image img-fluid"
              src="/Assets/img/soulution/herobanner.png"
              alt="E-commerce Solutions in Kuwait - Custom Online Store Development by Lucid"
            />
          </div>

          <div className="col-md-6 col-md-6 col-12 col-lg-6 hero-content pt-3">
            <motion.div
              initial={{ y: -100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                ease: "easeIn",
                duration: 1,
              }}
            >
              <button className="blue-button">
                {t("solution_page.ecommerce")}
              </button>
              <h1 className="hero-title">
                {t("solution_page.ecommerce_solutions_sentence_1")}
              </h1>
            </motion.div>
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                opacity: { duration: 1 },
                ease: "easeIn",
                duration: 1,
              }}
            >
              <p className="hero-desc">
                {t("solution_page.ecommerce_solutions_sentence_2")}
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
