import React from "react";
import "./TopBanner.css";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { motion } from "framer-motion";
const TopBanner = ({ data }) => {
  const {
    pageTitle,
    pageTitleBlu,
    desc,
    services,
    imageSrc,
    banner_image_alt,
  } = data;
  const motionSettings = {
    initial: { x: -100, opacity: 0 },
    whileInView: { x: 0, opacity: 1 },
    transition: {
      delay: 0.2,
      x: { type: "spring", stiffness: 60 },
      opacity: { duration: 1 },
      ease: "easeIn",
      duration: 1,
    },
  };
  return (
    <div>
      <section className="graphic">
        <div className="graphic-sp-container container">
          <motion.div {...motionSettings}>
            <h1 className="graphic-title">{pageTitle}</h1>
            <h1 className="title-span">{pageTitleBlu}</h1>
          </motion.div>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <motion.div {...motionSettings}>
                <p className="graphic-desc">{desc}</p>

                <div className="lists">
                  {services.map((service, index) => (
                    <p key={index}>
                      <IoCheckmarkDoneOutline className="mark-icon" /> {service}
                    </p>
                  ))}
                </div>
              </motion.div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 image-container">
              <motion.img
                initial={{ x: 100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                  duration: 1,
                }}
                className="img-fluid gray-img"
                src={imageSrc}
                alt={banner_image_alt}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopBanner;
