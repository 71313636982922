import React from "react";

const RtlSwitch = ({ isYearly, onToggle }) => {
  const rotatedStyle = {
    transform: "rotate(180deg)",
  };

  return (
    <div className={`col-4 col-lg-2 arf ${isYearly ? "checked" : ""}`}>
      <label style={rotatedStyle} className="switch" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          checked={isYearly}
          onChange={() => onToggle(!isYearly)}
        />
        <div className="slider round"></div>
      </label>
    </div>
  );
};

export default RtlSwitch;
