import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { FaInstagram, FaArrowRight } from "react-icons/fa";
import { PiGooglePlayLogoLight } from "react-icons/pi";
import { AiFillHome } from "react-icons/ai";
import { Navbar, Nav, Button, NavDropdown } from "react-bootstrap";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next, { t } from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";

const DollarImage = "/Assets/img/doller.svg";

const languages = [
  {
    code: "en",
    name: "English",
    dir: "ltr",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "kw",
  },
];

const Header = () => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t, ready } = useTranslation();
  const [flag, setFlag] = useState("");

  useEffect(() => {
    if (currentLanguageCode === "en") {
      setFlag("gb");
    } else {
      setFlag("kw");
    }
  }, [currentLanguageCode]);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  const [activeLink, setActiveLink] = useState("Home");
  const [dropdownTitle, setDropdownTitle] = useState(
    currentLanguageCode === "en" ? "Services" : "الخدمات"
  );

  const handleLinkClick = (link) => {
    setActiveLink(link);
    if (link !== "Services") setNavOpen(false);
  };

  const handleDropdownClick = (item) => {
    setDropdownTitle(item.text);
    setNavOpen(false);
  };

  const [isNavOpen, setNavOpen] = useState(false);
  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  const getPageIcon = () =>
    activeLink && activeLink !== "Home" ? (
      <img src={DollarImage} alt="Dollar" />
    ) : (
      <AiFillHome />
    );

  if (!ready) return "loading translations...";
  const navItems = t("navItems", { returnObjects: true });

  return (
    <header className="tech-header">
      <div className="tech-nav-top-bar">
        <div className="container">
          <img
            style={{ width: 250, height: 48 }}
            src="/lucid_logo.png"
            alt="lucid innovation logo"
          />
          <a href="/">
            <PiGooglePlayLogoLight />
          </a>
          <a href="/">
            <img
              src="/Assets/img/AppStore_.svg"
              alt="find lucid on app store"
            />
          </a>
          <a href="/">
            <FaInstagram />
          </a>
        </div>
      </div>
      <div className="container">
        <Navbar className="bg-body-tertiary" expand="lg" variant="dark">
          <Navbar.Brand href="/">{getPageIcon()}</Navbar.Brand>
          <FiMenu
            aria-controls="navbarScroll"
            onClick={toggleNav}
            className="custom-toggler d-block d-lg-none"
            style={{ marginRight: "2rem", fontSize: "2rem" }}
          />
          <Navbar.Collapse
            id="navbarScroll"
            className={isNavOpen ? "show" : "justify-content-between"}
          >
            <Nav
              className="mr-auto my-2 my-lg-0 navbar-nav-scroll"
              style={{ "--bs-scroll-height": "200px" }}
            >
              {navItems.map((navItem) => (
                <Nav.Item className="p-0 thisis-navitemtag" key={navItem.id}>
                  {navItem.dropdown ? (
                    <NavDropdown
                      title={dropdownTitle}
                      id={`dropdown-${navItem.id}`}
                      className={`thisis-navdropdown p-0 ${
                        activeLink === navItem.id ? "active" : ""
                      }`}
                      onClick={() => handleLinkClick(navItem.id)}
                    >
                      {navItem.items.map((subItem) => (
                        <NavDropdown.Item
                          className="thisis-navidropdownitem"
                          key={subItem.id}
                          as={Link}
                          to={subItem.path}
                          onClick={() => handleDropdownClick(subItem)}
                        >
                          {subItem.text}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  ) : (
                    <Link
                      to={navItem.path}
                      className={`nav-link ${
                        activeLink === navItem.id ? "active" : ""
                      }`}
                      onClick={() => handleLinkClick(navItem.id)}
                    >
                      {navItem.text}
                    </Link>
                  )}
                </Nav.Item>
              ))}
            </Nav>

            <form className="d-flex align-items-center" role="search">
              {/* <div
                className={`dropdown ${
                  currentLanguage.dir === "rtl" ? "dropdown-direction" : ""
                }`}
              >
                <button
                  className={`btn btn-link dropdown-toggle `}
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className={`fi fi-${flag} `}></span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {languages.map(({ code, name, country_code }) => (
                    <li key={country_code}>
                      <button
                        className={classNames("dropdown-item bg-white", {
                          disabled: currentLanguageCode === code,
                        })}
                        onClick={() => {
                          i18next.changeLanguage(code);
                        }}
                      >
                        <span
                          className={`fi fi-${country_code} mx-2`}
                          style={{
                            opacity: currentLanguageCode === code ? 0.5 : 1,
                          }}
                        ></span>
                        {name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div> */}

              <Button
                variant="outline-success"
                type="submit"
                className="d-flex align-items-center justify-content-between ps-4"
              >
                <h6 className="pt-2"> {t("get_a_quote")} </h6>
                <div className="circle-box">
                  <FaArrowRight />
                </div>
              </Button>
            </form>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
