import React from "react";
import "./faq.css";
import { Accordion } from "react-bootstrap";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";

  const faqData = t("solution_page.faqData", {
    returnObjects: true,
  });

  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="cards-content">
              <motion.button
                initial={{ y: -100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                }}
                className="blue-button"
              >
                {t("solution_page.faqs")}
              </motion.button>
              <motion.h2
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                  duration: 1,
                }}
              >
                {t("solution_page.faqs_full")}
              </motion.h2>
            </div>
          </div>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: "spring", stiffness: 60 },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 1,
            }}
            className="col-lg-8 col-md-12 faqs"
          >
            <Accordion>
              {faqData?.map((faq, index) => (
                <Accordion.Item eventKey={faq.eventKey} key={index}>
                  <Accordion.Header>{faq.question}</Accordion.Header>
                  <Accordion.Body>{faq.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
