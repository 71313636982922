import React from "react";
import "./StepsCard.css";
import { motion } from "framer-motion";

const StepsCard = ({ stepsData }) => {
  return (
    <section className="steps-section">
      <div className="stripes"></div>
      <div className="container con">
        <motion.div
          initial={{ y: -200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            opacity: { duration: 1 },
            ease: "easeIn",
          }}
          className="title-container"
        >
          <h1 className="simple-steps  ">{stepsData.secTitle}</h1>
          <h1 className="com-project ">{stepsData.secTitleBlu}</h1>
          <div className="bg-grid"></div>
        </motion.div>

        <div className="row cards-sec">
          {stepsData.steps.map((step, index) => (
            <motion.div
              layout
              initial={{ opacity: 0, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{
                delay: 0.2,
                duration: 1,
                ease: "easeOut",
              }}
              key={index}
              className=" col-lg-6 col-12"
            >
              <div className="card-custom">
                <h3>{step.step}</h3>
                <h2>{step.title}</h2>
                <p>{step.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StepsCard;
