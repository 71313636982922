import { useState, useEffect } from "react";

function getWindowDimensions() {
  const windowWidth =
    window.innerWidth -
    (window.innerWidth - document.documentElement.clientWidth);
  const windowHeight = window.innerHeight; // Get the height of the window

  return { windowWidth, windowHeight };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    // Force recalculation on initial render
    handleResize();
    setIsReady(true);

    // Add resize listener
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { ...windowDimensions, isReady };
}
