import React from "react";
import "./GraphicDesign.css";
import TopBanner from "../../../Shared/ServicePageTopBanner/TopBanner";
import RtoLVector from "../../../UI-Style/RtoLVector";
import Steps from "../../../Shared/StepsCard/StepsCard";
import LtoR from "../../../UI-Style/LtoRVector";
import Video from "../../../Shared/Video/Video";
import ServicePricingTable from "../../../Shared/PricingTable/ServicePricingTable";
import Contact from "../../Home/Contact/Contact";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
const GraphicDesign = () => {
  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";
  const pricingData = t("pricing.PricingTable.pricingDataGraphic", {
    returnObjects: true,
  });

  const graphicDesignSteps = t("graphic_design_page.graphicDesignSteps", {
    returnObjects: true,
  });
  const topBannerData = t("graphic_design_page.topBannerData", {
    returnObjects: true,
  });

  return (
    <div>
      <Helmet>
        <title>Graphic Design Services in Kuwait | Lucid</title>
        <meta
          name="description"
          content="Enhance your brand identity with Lucid's professional graphic design services in Kuwait. We create visually compelling designs for websites, logos, and marketing materials."
        />
        <link
          rel="canonical"
          href="https://lucid.com.kw/service/graphic-design"
        />
      </Helmet>

      <TopBanner data={topBannerData} />
      <RtoLVector />
      <Steps stepsData={graphicDesignSteps} />
      <LtoR />
      <Video />
      <RtoLVector />
      <ServicePricingTable pricingData={pricingData} />
      <LtoR />
      <Contact />
    </div>
  );
};

export default GraphicDesign;
