import React from "react";
import TopBanner from "../../../Shared/ServicePageTopBanner/TopBanner";
import RtoLVector from "../../../UI-Style/RtoLVector";
import StepsCard from "../../../Shared/StepsCard/StepsCard";
import LtoR from "../../../UI-Style/LtoRVector";
import Video from "../../../Shared/Video/Video";
import ServicePricingTable from "../../../Shared/PricingTable/ServicePricingTable";
import Contact from "../../Home/Contact/Contact";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
const SWTesting = () => {
  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";
  const pricingData = t("pricing.PricingTable.pricingDataSwTest", {
    returnObjects: true,
  });

  const topBannerData = t("sw_testing_page.topBannerData", {
    returnObjects: true,
  });

  const swTestSteps = t("sw_testing_page.swTestSteps", {
    returnObjects: true,
  });

  return (
    <div>
      <Helmet>
        <title>Software Testing Services in Kuwait | Lucid</title>
        <meta
          name="description"
          content="Ensure your software is bug-free with Lucid's software testing services in Kuwait. We provide comprehensive testing solutions to improve your software's quality and performance."
        />
        <link
          rel="canonical"
          href="https://lucid.com.kw/service/software-testing"
        />
      </Helmet>

      <TopBanner data={topBannerData} />
      <RtoLVector />
      <StepsCard stepsData={swTestSteps} />
      <LtoR />
      <Video />
      <RtoLVector />
      <ServicePricingTable pricingData={pricingData} />
      <LtoR />
      <Contact />
    </div>
  );
};

export default SWTesting;
