import React from "react";
import "./Seoservices.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const SEOServices = () => {
  const { t, i18n, ready } = useTranslation();

  if (!ready) return "loading translations...";
  const SeoServices = t("landing_page", {
    returnObjects: true,
  });
  const seoServiceData = t("landing_page.SeoServices.seoServiceData", {
    returnObjects: true,
  });

  return (
    <>
      <section className="tech-about-seo-services">
        <div className="container">
          <div className="row">
            <motion.div
              initial={{ y: -100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
              }}
              className="col-12"
            >
              <h5> {t("landing_page.SeoServices.title")}</h5>
              <h1>
                <span>SEO</span> {t("landing_page.SeoServices.title")}
              </h1>
              <p>{t("landing_page.SeoServices.subTitle")}</p>
            </motion.div>
            {seoServiceData.map((service, index) => (
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                  duration: 1,
                }}
                key={index}
                className="col-12 col-lg-4 col-md-6"
              >
                <div className="card">
                  <div className="about-seo-service-card-img">
                    <img
                      src={service.imgSrc}
                      alt={`SEO Service ${index + 1}`}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">{service.title}</h5>
                    <p className="card-text">{service.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default SEOServices;
