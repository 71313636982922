import React from "react";
import "./Pricingtable.css";

import { FiCheck } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { motion } from "framer-motion";
import RtlSwitch from "./RtlSwitch";
import { useTranslation } from "react-i18next";
const Buycards = ({ pricePackageData }) => {
  const [isYearly, setIsYearly] = useState(false);

  const formatNumberToArabic = (number) => {
    const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()
      .split("")
      .map((digit) => (/\d/.test(digit) ? arabicNumbers[digit] : digit))
      .join("");
  };

  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";

  return (
    <>
      <div className="buycards">
        <section className="tech-about-seo-packages bg-img-pricing">
          <div className="container">
            <motion.div
              initial={{ y: -200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                ease: "easeIn",
              }}
            >
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <h5>{pricePackageData.mini_title}</h5>
                    <h1>{pricePackageData.title}</h1>
                    <p>{pricePackageData.subtitle}</p>
                  </div>
                </div>
              </div>

              <div className="row mt-package-toggle">
                <div className="col-12 col-lg-5">
                  <div className="row">
                    <div className="col-4 col-lg-5">
                      {pricePackageData.pay_monthly}
                    </div>
                    {i18n.language === "ar" ? (
                      <RtlSwitch isYearly={isYearly} onToggle={setIsYearly} />
                    ) : (
                      <div className="col-4 col-lg-2">
                        <label className="switch" htmlFor="checkbox">
                          <input
                            type="checkbox"
                            id="checkbox"
                            checked={isYearly}
                            onChange={() => setIsYearly(!isYearly)}
                          />
                          <div className="slider round"></div>
                        </label>
                      </div>
                    )}
                    <div className="col-4 col-lg-5">
                      {pricePackageData.pay_yearly}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                duration: 1,
              }}
              className="arrow-image"
            >
              <img
                src="/Assets/img/greenarrow.png"
                alt="arrow"
                className="arrow"
              />
              <h4 style={{ color: "#508A95" }}>
                {pricePackageData.arrow_line}
              </h4>
            </motion.div>
            {/* new  */}
            <div className="row">
              {pricePackageData.pricingData.map((plan, index) => (
                <motion.div
                  initial={
                    index === 0
                      ? { x: -100, opacity: 0 }
                      : index === 1
                      ? { opacity: 1 }
                      : index === 2
                      ? { x: 100, opacity: 0 }
                      : { opacity: 1 }
                  }
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 1 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className={`col-lg-4 col-12 ${
                    index === 2 ? "col-md-12" : "col-md-6"
                  }`}
                  key={index}
                >
                  <div
                    className="pricing-table-card mx-auto "
                    style={{ marginTop: "2rem", marginBlock: "3rem" }}
                  >
                    <h3 className="color-gr">{plan.title}</h3>
                    <p>{plan.description}</p>
                    <h1>
                      {i18n.language === "ar"
                        ? formatNumberToArabic(
                            isYearly ? plan.price * 12 : plan.price
                          )
                        : isYearly
                        ? plan.price * 12
                        : plan.price}
                      {` ${plan.currency}`}
                      <span className="color-gr">
                        /{" "}
                        {isYearly
                          ? pricePackageData.year
                          : pricePackageData.month}
                      </span>
                    </h1>
                    <button className="pri-btn">
                      {pricePackageData.buy_now}
                    </button>
                    {plan.features.map((feature, featureIndex) => (
                      <div
                        className={`pricing-list-row ${
                          (plan.title === "Individual" && featureIndex > 1
                            ? "red"
                            : "") ||
                          (plan.title === "Professional" && featureIndex > 3
                            ? "red"
                            : "") ||
                          ""
                        }`}
                        key={featureIndex}
                        onMouseEnter={(e) =>
                          e.currentTarget.classList.add("hovered-item")
                        }
                        onMouseLeave={(e) =>
                          e.currentTarget.classList.remove("hovered-item")
                        }
                      >
                        {pricePackageData.page === "Solutions" ? (
                          <>
                            <div className={`pricing-list-icon bg-greeen`}>
                              <FiCheck color="#508A95" />
                            </div>
                            <div className="pricing-list-text">
                              <p className="black solutions-items">{feature}</p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className={`pricing-list-icon ${
                                (plan.title === "Individual" && featureIndex < 2
                                  ? "bg-greeen"
                                  : "") ||
                                (plan.title === "Professional" &&
                                featureIndex < 4
                                  ? "bg-greeen"
                                  : "") ||
                                (plan.title === "Business" && featureIndex < 6
                                  ? "bg-greeen"
                                  : "") ||
                                "bg-light-green"
                              }`}
                            >
                              {plan.title === "Business" && featureIndex < 6 ? (
                                <FiCheck color="#508A95" />
                              ) : plan.title === "Individual" &&
                                featureIndex < 2 ? (
                                <FiCheck color="#508A95" />
                              ) : plan.title === "Professional" &&
                                featureIndex < 4 ? (
                                <FiCheck color="#508A95" />
                              ) : (
                                <RxCross1 color="#191D23" />
                              )}
                            </div>
                            <div className="pricing-list-text">
                              <p
                                className={
                                  (plan.title === "Individual" &&
                                  featureIndex > 1
                                    ? "red"
                                    : "") ||
                                  (plan.title === "Professional" &&
                                  featureIndex > 3
                                    ? "red"
                                    : "") ||
                                  "black"
                                }
                              >
                                {feature}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      </div>
      {/* <div className="up-toSpace">
        <LtoR />
      </div> */}
    </>
  );
};

export default Buycards;
