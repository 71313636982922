import React from "react";
import "./Services.css";
import { motion } from "framer-motion";

const TechServices = ({ cardDatas }) => {
  return (
    <div className="services">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="cards-content">
              <motion.button
                initial={{ y: -100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                }}
              >
                {cardDatas.button_text}
              </motion.button>
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                }}
              >
                <div>
                  <h2>{cardDatas.title}</h2>
                  <p>{cardDatas.we_believe}</p>
                </div>
              </motion.div>
            </div>
          </div>
          {cardDatas?.cardData?.map((service, index) => (
            <motion.div
              layout
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{
                delay: 0.2,
                duration: 1,
                ease: "easeOut",
              }}
              key={index}
              className={`col-12 col-md-6 col-lg-4 service-card-grid ${
                index === 4 ? "" : ""
              }`}
            >
              <div className="services-cards">
                <div>
                  {cardDatas?.page === "home" && (
                    <img src={service?.imgSrc} alt={service?.title} />
                  )}
                  {cardDatas?.page === "solutions" && (
                    <>
                      <img
                        className="service-icon-blu"
                        src={service?.imgSrc}
                        alt={service?.title}
                      />
                      <img
                        className="service-icon-white"
                        src={service?.imgWhite}
                        alt={service?.title}
                      />
                    </>
                  )}
                </div>
                <div>
                  <h3 className="h-white">{service.title}</h3>
                  <p className="h-white">{service.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechServices;

// import React from "react";
// import "./Services.css";
// import { motion } from "framer-motion";

// const TechServices = ({ cardDatas }) => {
//   return (
//     <div className="services">
//       <div className="container">
//         <div className="row">
//           <div className="col-12 col-md-6 col-lg-4">
//             <div className="cards-content">
//               <motion.button
//                 initial={{ x: -100, opacity: 0 }}
//                 whileInView={{ x: 0, opacity: 1 }}
//                 transition={{
//                   delay: 0.2,
//                   x: { type: "spring", stiffness: 60 },
//                   opacity: { duration: 1 },
//                   ease: "easeIn",
//                 }}
//               >
//                 {cardDatas.button_text}
//               </motion.button>
//               <motion.div
//                 initial={{ x: -100, opacity: 0 }}
//                 whileInView={{ x: 0, opacity: 1 }}
//                 transition={{
//                   delay: 0.2,
//                   x: { type: "spring", stiffness: 60 },
//                   opacity: { duration: 1 },
//                   ease: "easeIn",
//                   duration: 1,
//                 }}
//               >
//                 <div>
//                   <h2>{cardDatas.title}</h2>
//                   <p>{cardDatas.we_believe}</p>
//                 </div>
//               </motion.div>
//             </div>
//           </div>
//           {cardDatas.cardData.map((service, index) => (
//             <motion.div
//               initial={{ y: 50, opacity: 0 }}
//               whileInView={{ y: 0, opacity: 1 }}
//               transition={{
//                 delay: 0.2,
//                 x: { type: "spring", stiffness: 60 },
//                 opacity: { duration: 1 },
//                 ease: "easeIn",
//                 // duration: 1,
//               }}
//               key={index}
//               className={`col-12 col-md-6 col-lg-4 service-card-grid ${
//                 index === 4 ? "" : ""
//               }`}
//             >
//               <div className="services-cards">
//                 <div>
//                   {cardDatas.page === "home" && (
//                     <img src={service.imgSrc} alt={service.title} />
//                   )}
//                   {cardDatas.page === "solutions" && (
//                     <>
//                       <img
//                         className="service-icon-blu"
//                         src={service.imgSrc}
//                         alt={service.title}
//                       />
//                       <img
//                         className="service-icon-white"
//                         src={service.imgWhite}
//                         alt={service.title}
//                       />
//                     </>
//                   )}
//                 </div>
//                 <div>
//                   <h3 className="h-white">{service.title}</h3>
//                   <p className="h-white">{service.description}</p>
//                 </div>
//               </div>
//               {/* </div> */}
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TechServices;
