import React from "react";
import { motion } from "framer-motion";
import useWindowDimensions from "../../hooks/GetDimension";
import Loader from "./Loader";

const RtoLVector = () => {
  const { windowWidth, isReady } = useWindowDimensions();
  if (!isReady) {
    return <Loader />;
  }

  return (
    <motion.div
      initial={{ x: windowWidth, opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      transition={{
        delay: 0.2,
        opacity: { duration: 1 },
        ease: "easeIn",
        duration: 1,
      }}
      className="container-fluid m-0 p-0"
    >
      <img
        src="/Assets/img/RtoL/VectorRtoL.svg"
        alt="VectorRtoL"
        srcSet=""
        className="w-100"
      />
    </motion.div>
  );
};

export default RtoLVector;
