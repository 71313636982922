import React from "react";
import "./Contact.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Contact = () => {
  const { t, i18n, ready } = useTranslation();

  if (!ready) return "loading translations...";

  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="contact d-flex justify-content-between align-items-star">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                duration: 1,
              }}
              className="contact-info"
            >
              <h1>{t("contact_page.want_to_start")}</h1>
              <p>{t("contact_page.lorem")}</p>
            </motion.div>
            {/* <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                duration: 1,
              }}
            > */}
            <div className="contact-form">
              <form>
                <motion.div
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 1 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="name">{t("contact_page.your_name")}</label>
                    <input type="text" id="name" className="  " />
                  </div>
                </motion.div>
                <motion.div
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 1 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="email">
                      {t("contact_page.your_email")}
                    </label>
                    <input type="email" id="email" className="  " />
                  </div>
                </motion.div>
                <motion.div
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 1 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="phone">
                      {t("contact_page.phone_number")}
                    </label>
                    <input type="text" id="phone" className="  " />
                  </div>
                </motion.div>
                <motion.div
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 1 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="message">
                      {t("contact_page.type_msg")}
                    </label>
                    <textarea id="message" className="  "></textarea>
                  </div>
                </motion.div>
              </form>
            </div>
          </div>
          <motion.div
            className="submit-button"
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              opacity: { duration: 1 },
              ease: "easeIn",
            }}
          >
            <div class="circle-button">
              <span class="button-text">{t("contact_page.submit_btn")}</span>
              <img
                src="/Assets/img/contact/arrow.svg"
                alt="arrow"
                class="button-arrow"
              />
            </div>
            {/* 
            <img
              src="/Assets/img/contact/Button.png"
              alt="Button Icon"
              className="img-fluid"
            /> */}
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Contact;
