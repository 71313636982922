import React from "react";
import PricingCard from "./PricingCard";
import { motion } from "framer-motion";
const ServicePricingTable = ({ pricingData }) => {
  return (
    <section className="graphic-pricing">
      <div className="container">
        <motion.div
          initial={{ y: -200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            ease: "easeIn",
          }}
          className="title-container"
        >
          <h1 className="graphic-title">{pricingData.best_package}</h1>
          <h1 className="title-span">{pricingData.title}</h1>
          <div className="bg-grid"></div>
        </motion.div>
      </div>
      <PricingCard pricingData={pricingData} />
    </section>
  );
};

export default ServicePricingTable;
