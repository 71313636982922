import React, { useState } from "react";
import "./Pricingtable.css";
import { RxCross1 } from "react-icons/rx";
import { FiCheck } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import RtlSwitch from "../../Pricing/PricingTable/RtlSwitch";
import { motion } from "framer-motion";

const PricingTable = () => {
  const [isYearly, setIsYearly] = useState(false);
  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";
  const pricingData = t("landing_page.PricingTable.pricingData", {
    returnObjects: true,
  });

  const formatNumberToArabic = (number) => {
    const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()
      .split("")
      .map((digit) => (/\d/.test(digit) ? arabicNumbers[digit] : digit))
      .join("");
  };
  return (
    <section className="tech-about-seo-packages">
      <div className="container">
        <motion.div
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            x: { type: "spring", stiffness: 60 },
            opacity: { duration: 1 },
            ease: "easeIn",
          }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div>
                <h5> {t("landing_page.PricingTable.mini_title")}</h5>
                <h1>
                  <span>{t("landing_page.PricingTable.titleFirst")}</span>
                  {t("landing_page.PricingTable.titleSecond")}
                </h1>
                <p>{t("landing_page.PricingTable.paragraph1")}</p>
              </div>
            </div>
          </div>
          <div className="row mt-package-toggle">
            <div className="col-12 col-lg-5">
              <div className="row">
                <div className="col-4 col-lg-5">
                  {t("landing_page.PricingTable.pay_monthly")}
                </div>
                {i18n.language === "ar" ? (
                  <RtlSwitch isYearly={isYearly} onToggle={setIsYearly} />
                ) : (
                  <div className="col-4 col-lg-2">
                    {/* Your existing switch code for LTR */}
                    <label className="switch" htmlFor="checkbox">
                      <input
                        type="checkbox"
                        id="checkbox"
                        checked={isYearly}
                        onChange={() => setIsYearly(!isYearly)}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>
                )}
                <div className="col-4 col-lg-5">
                  {t("landing_page.PricingTable.pay_yearly")}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            x: { type: "spring", stiffness: 60 },
            opacity: { duration: 1 },
            ease: "easeIn",
            duration: 1,
          }}
          className="arrow-image"
        >
          <img src="/Assets/img/arrow.png" alt="arrow" className="arrow" />
          <h4>{t("landing_page.PricingTable.save_25")}</h4>
        </motion.div>
        <div className="row">
          {pricingData.map((plan, index) => (
            <motion.div
              initial={
                index === 0
                  ? { x: -100, opacity: 0 }
                  : index === 1
                  ? { opacity: 1 }
                  : index === 2
                  ? { x: 100, opacity: 0 }
                  : { opacity: 1 }
              }
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                duration: 1,
              }}
              className={`col-lg-4 col-12 ${
                index === 2 ? "col-md-12" : "col-md-6"
              }`}
              key={index}
            >
              <div
                className="pricing-table-card mx-auto"
                style={{ marginTop: "2rem" }}
              >
                <h3>{plan.title}</h3>
                <p>{plan.description}</p>
                <h1>
                  {i18n.language === "ar"
                    ? formatNumberToArabic(
                        isYearly ? plan.price * 12 : plan.price
                      )
                    : isYearly
                    ? plan.price * 12
                    : plan.price}
                  {` ${plan.currency}`}
                  <span className="color-gr">
                    /{" "}
                    {isYearly
                      ? t("pricing.PricingTable.year")
                      : t("pricing.PricingTable.month")}
                  </span>
                </h1>
                <button> {t("landing_page.PricingTable.buy_now")}</button>
                {plan.features.map((feature, featureIndex) => (
                  <div className="pricing-list-row" key={featureIndex}>
                    <div
                      className={`pricing-list-icon ${
                        (plan.title === "Beginner" && featureIndex < 2
                          ? "bg-green"
                          : "") ||
                        (plan.title === "SMB & Local" && featureIndex < 4
                          ? "bg-green"
                          : "") ||
                        (plan.title === "High Growth" && featureIndex < 6
                          ? "bg-green"
                          : "") ||
                        "bg-light-greeen"
                      }`}
                    >
                      {plan.title === "High Growth" && featureIndex < 6 ? (
                        <FiCheck color="#364163" />
                      ) : plan.title === "SMB & Local" && featureIndex < 4 ? (
                        <FiCheck color="#364163" />
                      ) : plan.title === "Beginner" && featureIndex < 2 ? (
                        <FiCheck color="#364163" />
                      ) : (
                        <RxCross1 color="#191D23" />
                      )}
                    </div>
                    <div className="pricing-list-text">
                      <p
                        className={
                          (plan.title === "Beginner" && featureIndex > 1
                            ? "red"
                            : "") ||
                          (plan.title === "SMB & Local" && featureIndex > 3
                            ? "red"
                            : "") ||
                          "black"
                        }
                      >
                        {feature}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingTable;
