import React from "react";
import "./SWDev.css";
import TopBanner from "../../../Shared/ServicePageTopBanner/TopBanner";
import RtoLVector from "../../../UI-Style/RtoLVector";
import StepsCard from "../../../Shared/StepsCard/StepsCard";
import LtoR from "../../../UI-Style/LtoRVector";
import Video from "../../../Shared/Video/Video";
import ServicePricingTable from "../../../Shared/PricingTable/ServicePricingTable";
import { useTranslation } from "react-i18next";
import Contact from "../../Home/Contact/Contact";
import { Helmet } from "react-helmet-async";
const SWDev = () => {
  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";
  const pricingData = t("pricing.PricingTable.pricingDataSw", {
    returnObjects: true,
  });

  const topBannerData = t("sw_dev_page.topBannerData", {
    returnObjects: true,
  });
  const swDevSteps = t("sw_dev_page.swDevSteps", {
    returnObjects: true,
  });

  return (
    <div>
      <Helmet>
        <title>Software Development Services in Kuwait | Lucid</title>
        <meta
          name="description"
          content="Build innovative and scalable software with Lucid's software development services in Kuwait. We specialize in custom solutions for businesses across various industries."
        />
        <link
          rel="canonical"
          href="https://lucid.com.kw/service/software-development"
        />
      </Helmet>

      <TopBanner data={topBannerData} />
      <RtoLVector />
      <StepsCard stepsData={swDevSteps} />
      <LtoR />
      <Video />
      <RtoLVector />
      <ServicePricingTable pricingData={pricingData} />
      <LtoR />
      <Contact />
    </div>
  );
};

export default SWDev;
