import React, { useEffect, useState } from "react";
import Banner from "./Bannar/Bannar";
import Services from "./Services/Services";
import Testimonials from "./Testimonials/Testimonials";
import Contact from "./Contact/Contact";
import "../Responsive.css";
import RtoLVector from "../../UI-Style/RtoLVector";
import Solutions from "./Solutions/Solutions";
import LtoR from "../../UI-Style/LtoRVector";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
const Home = () => {
  const { t, ready } = useTranslation();
  if (!ready) return "loading translations...";
  const testimonialData = t("testimonialData1", { returnObjects: true });
  const serviceData = t("serviceSection", { returnObjects: true });

  return (
    <div>
      <Helmet>
        <title>
          Lucid | Web Solutions in Kuwait | SEO, E-commerce, Graphic Design &
          More
        </title>
        <meta
          name="description"
          content="Lucid provides comprehensive web solutions in Kuwait including SEO, software development, graphic design, and social media marketing to boost your business online."
        />
        <link rel="canonical" href="https://lucid.com.kw/" />
      </Helmet>

      <Banner testimonialData={testimonialData} t={t} page={"Home"} />
      <RtoLVector />
      <Services cardDatas={serviceData} />
      <LtoR />
      <Solutions />
      <RtoLVector />

      {/* <Testimonials /> */}
      {/* <LtoR /> */}
      <Contact />
    </div>
  );
};

export default Home;
