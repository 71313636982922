import React from "react";
import "./Ourprogress.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const WorkWithUs = () => {
  const { t, i18n, ready } = useTranslation();

  if (!ready) return "loading translations...";
  const SeoServices = t("landing_page", {
    returnObjects: true,
  });
  const workData = t("landing_page.OurProgress.workData", {
    returnObjects: true,
  });

  return (
    <section className="tech-about-work-with-us">
      <div className="container">
        <div className="row">
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: "spring", stiffness: 60 },
              opacity: { duration: 1 },
              ease: "easeIn",
            }}
            className="col-lg-12"
          >
            <h5>{t("landing_page.OurProgress.mini_title")}</h5>
            <h1>
              {t("landing_page.OurProgress.titleFirst")}{" "}
              <span>{t("landing_page.OurProgress.titleSecond")}</span>
              {t("landing_page.OurProgress.titleThird")}
            </h1>
            <p>{t("landing_page.OurProgress.subtitle")}</p>
          </motion.div>
        </div>
        <div className="row mt-work">
          {workData.map((work, index) => (
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                // duration: 1,
              }}
              key={index}
              className="col-lg-3 col-md-6 col-12"
            >
              <div className="card-progres" style={{ marginTop: "3rem" }}>
                <div className="about-work-card-img">
                  <img
                    className="position-absolute top-0 start-50 translate-middle"
                    src={work.imgSrc}
                    alt={`Work ${index + 1}`}
                    id="color-img"
                  />
                </div>
                <div
                  className="card-body mt-5 mt-lg-0"
                  style={{ marginTop: "1rem" }}
                >
                  <h5 className="card-title">{work.title}</h5>
                  <p className="card-text">{work.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkWithUs;
