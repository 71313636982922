import React from "react";
import useWindowDimensions from "../../hooks/GetDimension";
import Loader from "./Loader";
import { motion } from "framer-motion";

const LtoR = () => {
  const { windowWidth, isReady } = useWindowDimensions();
  if (!isReady) {
    return <Loader />;
  }
  return (
    <motion.div
      initial={{ x: -windowWidth, opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      transition={{
        delay: 0.2,
        opacity: { duration: 1 },
        ease: "easeIn",
        duration: 1,
      }}
    >
      <div className="container-fluid m-0 p-0">
        <img
          src="/Assets/img/LtoR/VectorLtoR.svg"
          alt="VectorLtoR"
          srcSet=""
          className="w-100"
        />
      </div>
    </motion.div>
  );
};

export default LtoR;
