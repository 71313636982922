import React, { useState } from "react";
import "./PricingCard.css";
import { FiCheck } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import RtlSwitch from "../../Pages/Pricing/PricingTable/RtlSwitch";
import { motion } from "framer-motion";
const PricingCard = ({ pricingData }) => {
  const [isYearly, setIsYearly] = useState(false);
  const { t, i18n, ready } = useTranslation();

  const formatNumberToArabic = (number) => {
    const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()
      .split("")
      .map((digit) => (/\d/.test(digit) ? arabicNumbers[digit] : digit))
      .join("");
  };

  if (!ready) return "loading translations...";

  return (
    <>
      <div className="buycards position-relative">
        <div className="stripes"></div>
        <section className="tech-about-seo-packages  pricing-packages">
          <div className="container">
            {pricingData.toggle === "Yes" && (
              <motion.div
                initial={{ y: -200, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  ease: "easeIn",
                }}
                className="row "
              >
                <div className="col-12 col-lg-5  switch-button-group">
                  <div className="row">
                    <div className="col-4 col-lg-5">
                      {t("pricing.PricingTable.pay_monthly")}
                    </div>
                    {i18n.language === "ar" ? (
                      <RtlSwitch isYearly={isYearly} onToggle={setIsYearly} />
                    ) : (
                      <div className="col-4 col-lg-2">
                        <label className="switch" htmlFor="checkbox">
                          <input
                            type="checkbox"
                            id="checkbox"
                            checked={isYearly}
                            onChange={() => setIsYearly(!isYearly)}
                          />
                          <div className="slider round"></div>
                        </label>
                      </div>
                    )}
                    <div className="col-4 col-lg-5">
                      {t("pricing.PricingTable.pay_yearly")}
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
            <div className="row">
              {pricingData.data.map((plan, index) => (
                <motion.div
                  initial={
                    index === 0
                      ? { x: -100, opacity: 0 }
                      : index === 1
                      ? { opacity: 0, scale: 0.95 }
                      : index === 2
                      ? { x: 100, opacity: 0 }
                      : { opacity: 1 }
                  }
                  whileInView={{ x: 0, opacity: 1, scale: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 1 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className={`col-lg-4 col-12 ${
                    index === 2 ? "col-md-12" : "col-md-6"
                  }`}
                  key={index}
                >
                  <div
                    className="pricing-table-card mx-auto"
                    style={{ marginTop: "2rem", marginBlock: "3rem" }}
                  >
                    <h3 className="color-">{plan.title}</h3>
                    <p className="plan-desc">{plan.description}</p>
                    {/* <h1 className="color-">{plan.price} K.D</h1> */}

                    {pricingData.toggle === "Yes" ? (
                      <h1 className="color-class">
                        {i18n.language === "ar"
                          ? formatNumberToArabic(
                              isYearly ? plan.price * 12 : plan.price
                            )
                          : isYearly
                          ? plan.price * 12
                          : plan.price}
                        {` ${plan.currency}`}
                        <span className="color-class">
                          /{" "}
                          {isYearly
                            ? t("pricing.PricingTable.year")
                            : t("pricing.PricingTable.month")}
                        </span>
                      </h1>
                    ) : (
                      <h1 className="color-class">
                        {i18n.language === "ar"
                          ? formatNumberToArabic(plan.price)
                          : plan.price}{" "}
                        {plan.currency}
                      </h1>
                    )}

                    <button className="pri-btn">
                      {t("pricing.PricingTable.buy_now")}
                    </button>
                    {plan.features.map((feature, featureIndex) => (
                      <div
                        className={`pricing-list-row ${
                          ((plan.title === "Start-Up" ||
                            plan.title === "Beginner") &&
                          featureIndex > 1
                            ? "red"
                            : "") ||
                          (plan.title === "Standard" && featureIndex > 3
                            ? "red"
                            : "") ||
                          ""
                        }`}
                      >
                        <div
                          className={`pricing-list-icon ${
                            ((plan.title === "Start-Up" ||
                              plan.title === "Beginner") &&
                            featureIndex < 2
                              ? "bg-greeen"
                              : "") ||
                            (plan.title === "Standard" && featureIndex < 4
                              ? "bg-greeen"
                              : "") ||
                            (plan.title === "Premium" && featureIndex < 6
                              ? "bg-greeen"
                              : "") ||
                            "bg-light-green"
                          }`}
                        >
                          {plan.title === "Premium" && featureIndex < 6 ? (
                            <FiCheck color="#508A95" />
                          ) : (plan.title === "Start-Up" ||
                              plan.title === "Beginner") &&
                            featureIndex < 2 ? (
                            <FiCheck color="#508A95" />
                          ) : plan.title === "Standard" && featureIndex < 4 ? (
                            <FiCheck color="#508A95" />
                          ) : (
                            <RxCross1 color="#191D23" />
                          )}
                        </div>
                        <div className="pricing-list-text  ">
                          <p
                            className={
                              ((plan.title === "Start-Up" ||
                                plan.title === "Beginner") &&
                              featureIndex > 1
                                ? "red"
                                : "") ||
                              (plan.title === "Standard" && featureIndex > 3
                                ? "red"
                                : "") ||
                              "black"
                            }
                          >
                            {feature}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PricingCard;
