import React from "react";
import "./Solution.css";
import Banner from "../Home/Bannar/Bannar";
import { useTranslation } from "react-i18next";
import HeroSection from "./HeroSection/HeroSection";
import RtoLVector from "../../UI-Style/RtoLVector";
import LtoR from "../../UI-Style/LtoRVector";
import TechServices from "../Home/Services/Services";
import Buycards from "../Pricing/PricingTable/Pricingtable";
import Contact from "../Home/Contact/Contact";
import FAQ from "./FAQ/FAQ";
import { Helmet } from "react-helmet-async";
const Solution = () => {
  const { t, i18n, ready } = useTranslation();

  if (!ready) return "loading translations...";

  const testimonialData = t("testimonialData1", { returnObjects: true });

  const solutionsData = t("solution_page.featuresSection", {
    returnObjects: true,
  });

  const pricingData = t("solution_page.PricingTable", {
    returnObjects: true,
  });

  return (
    <div>
      <Helmet>
        <title>E-commerce Solutions in Kuwait | Lucid</title>
        <meta
          name="description"
          content="Boost your online store with Lucid's E-commerce solutions in Kuwait. We provide custom e-commerce websites and platforms that drive sales and growth."
        />
        <link rel="canonical" href="https://lucid.com.kw/solutions/ecommerce" />
      </Helmet>

      <Banner testimonialData={testimonialData} t={t} />
      <LtoR />
      <HeroSection />
      <RtoLVector />
      <TechServices cardDatas={solutionsData} t={t} />
      <LtoR />
      <Buycards pricePackageData={pricingData} />
      <RtoLVector />
      <FAQ />
      <LtoR />
      <Contact />
    </div>
  );
};

export default Solution;
