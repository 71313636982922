import React from "react";
import "./Bannar.css";
import { useTranslation } from "react-i18next";
const Bannar = () => {
  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";

  return (
    <>
      <div className="pricing-bannar">
        <div className="container">
          <div className="row align-items-center" id="Media-resp">
            <div className="col-12 col-lg-6 ">
              <div className="pricing-bannar-img">
                <img
                  src="/Assets/img/Pricing plans-pana 1.svg"
                  alt=""
                  srcSet=""
                  className="pricing-image"
                />
              </div>
            </div>
            <div className="col-12 col-lg-6 left-sp" id="margin">
              <div className="pricing-bannar-content mt-4 mt-lg-0 mt-md-0 text-center text-lg-start text-md-start float-start float-md-end float-lg-end">
                <p style={{ fontWeight: "600" }}>
                  {t("pricing.Bannar.mini_title")}
                </p>
                <h1 className="heading">
                  {t("pricing.Bannar.titleFirst")}
                  <br /> {t("pricing.Bannar.titleSecond")}{" "}
                  <span> {t("pricing.Bannar.titleThird")}</span>
                </h1>
                <p style={{ color: "#000000" }}>
                  {t("pricing.Bannar.paragraph")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bannar;
