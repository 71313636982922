import React from "react";
import "./Seoimportant.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Seoimportant = () => {
  const { t, i18n, ready } = useTranslation();

  if (!ready) return "loading translations...";

  const seoContent = t("landing_page.SeoImportant.seoContent", {
    returnObjects: true,
  });
  const placeholders = t("landing_page.SeoImportant.placeholders", {
    returnObjects: true,
  });

  return (
    <div>
      <section className="tech-about-seo-importance">
        <div className="row">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: "spring", stiffness: 60 },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 1,
            }}
            className="col-lg-6 col-12 p-0"
          >
            <div className="left-content">
              <h1 className="text-center text-lg-start text-md-start">
                {t("landing_page.SeoImportant.title")}
                <span>SEO {t("landing_page.SeoImportant.titleLast")}</span>
              </h1>
              {seoContent.map((content, index) => (
                <div key={index}>
                  <h5>{content.title}</h5>
                  <p>{content.description}</p>
                </div>
              ))}
            </div>
          </motion.div>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: "spring", stiffness: 60 },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 1,
            }}
            className="col-lg-6 col-12"
            style={{ backgroundColor: "rgba(149, 167, 251, 0.1)" }}
          >
            <div className="right">
              <div className="right-content">
                <p>{t("landing_page.SeoImportant.paragraph")}</p>
                <form>
                  {placeholders.map((placeholder, index) => (
                    <div className="mb-3" key={index}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={placeholder}
                        id="input"
                      />
                    </div>
                  ))}
                  <button type="submit" className="btn btn-primary submit-btn">
                    {t("landing_page.SeoImportant.buttonText")}
                  </button>
                </form>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Seoimportant;
