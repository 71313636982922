import React from "react";
import "./Video.css";

const Video = () => {
  return (
    <div className="video-section">
      <div className="container">
        <img
          src="/Assets/img/Video.png"
          className="img-fluid"
          alt="video frame"
        />
      </div>
    </div>
  );
};

export default Video;
