import React from "react";
import LandingBannar from "../LandingBannar/Landingbannar";
import Seo from "../Seo/Seo";
import SeoServices from "./Seoservices";
import SeoImportant from "../SeoImportant/Seoimportant";
import OurProgress from "../OurProgress/Ourprogress";
import PricingTable from "../PricingTable/Pricingtable";
import Contact from "../../Home/Contact/Contact";
import RtoLWhiteArrow from "../../../UI-Style/RtoLWhiteArrow";
import { Helmet } from "react-helmet-async";
const Landing = () => {
  return (
    <div className="overflow-hidden">
      <Helmet>
        <title>SEO Services in Kuwait | Lucid</title>
        <meta
          name="description"
          content="Improve your website's search engine ranking with Lucid's SEO services in Kuwait."
        />
        <link rel="canonical" href="https://lucid.com.kw/service/seo" />
      </Helmet>
      <LandingBannar />
      <Seo />
      <SeoServices />
      <SeoImportant />
      <OurProgress />
      <PricingTable />
      <RtoLWhiteArrow />
      <Contact />
    </div>
  );
};

export default Landing;
