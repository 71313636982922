import React from "react";
import { FaStar } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import "./Bannar.css";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";

const Banner = ({ testimonialData, t, page }) => {
  return (
    <section className="tech-banner">
      <Container>
        <Row>
          <Col lg={8} md={6}>
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                duration: 1,
              }}
            >
              <div className="tech-banner-content">
                <div>
                  <div>
                    {page === "Home" ? (
                      <h1>
                        {t("welcome_message")}

                        <span> {t("welcome_message_span")}</span>
                      </h1>
                    ) : (
                      <h1>
                        <span> {t("solution_page.meet_our")}</span>{" "}
                        {t("solution_page.ecommerce")}{" "}
                        <span>{t("solution_page.solution_for_business")}</span>
                      </h1>
                    )}
                  </div>

                  <div>
                    <p className="mt-4">
                      {t("solution_page.business_success")}
                    </p>
                    <div className="mt-lg-1">
                      {page === "Home" ? (
                        <Button variant="primary">{t("give_us_a_shot")}</Button>
                      ) : (
                        <Button variant="primary">
                          {t("solution_page.get_quote")}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </Col>
          <Col lg={4} md={6}>
            {/* {testimonialData.map((testimonial, index) => (
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                  duration: 1,
                }}
                key={index}
                className="banner-testimonial-card"
              >
                <div>
                  {Array.from({ length: testimonial.stars }, (_, starIndex) => (
                    <FaStar key={starIndex} />
                  ))}
                </div>
                <div>
                  <h3>{testimonial.title}</h3>
                  <p>{testimonial.text}</p>
                </div>
                <div className="d-flex align-items-center gap-3 mt-2">
                  <img src={testimonial.clientImage} alt="Client" />
                  <h4 className="pt-2">{testimonial.clientName}</h4>
                </div>
              </motion.div>
            ))} */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
