import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import toast, { Toaster } from "react-hot-toast";

const Footer = () => {
  const { t, ready } = useTranslation();

  const [email, setEmail] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        "https://lucid-kw.vercel.app/send-email",
        {
          email: email,
        }
      );
      toast.success("Thank you for subscribing!");
      setEmail("");
    } catch (error) {
      toast.error("There was an error sending the email.");
    }
  };

  if (!ready) return "loading translations...";
  const contactInfo = t("footer.contactInfo", { returnObjects: true });
  const menuItemsLinks = t("footer.menuItemsLinks", { returnObjects: true });
  const footerBottomlinks = t("footer.footerBottomlinks", {
    returnObjects: true,
  });

  return (
    <footer className="tech-footer">
      <div className="container">
        <div className="row ">
          <div className="col-lg-4">
            <img
              style={{ width: 250 }}
              src="/Assets/img/footer/lucid_logo.png"
              alt="lucid logo"
              className="mb-4"
            />

            <div className="row">
              {contactInfo.map((info, index) => (
                <div
                  key={index}
                  className="d-flex gap-3 mt-3 contact-info-footer "
                >
                  <img
                    style={{ width: 48 }}
                    src={info.icon}
                    alt={info.content}
                  />
                  <div>
                    <div className="d-flex ">
                      <h6 style={{ minWidth: "100px" }}>{info.title}</h6>
                      <h6 className="con">{info.content}</h6>
                    </div>
                    {info.title2 && info.content2 && (
                      <div className="d-flex">
                        <h6 style={{ minWidth: "100px" }}>{info.title2}</h6>
                        <h6 className="con">{info.content2}</h6>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="row">
              {contactInfo.map((info, index) => (
                <div
                  key={index}
                  className="d-flex  gap-3 mt-3 contact-info-footer"
                >
                  <img style={{ width: 48 }} src={info.icon} alt="" />
                  <div>
                    <h6>{info.title} </h6>
                    <h6>{info.title2} </h6>
                  </div>
                  <div>
                    <h6 className="con">{info.content}</h6>
                    <h6 className="con">{info.content2}</h6>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
          <div className="col-lg-3 col-md-12 mt-md-5 mt-5 mt-lg-0 ">
            <div className="row quick-links">
              <h2>{t("footer.quick_links")}</h2>

              <ul className="mt-md-2 mt-sm-2  mt-lg-5">
                {menuItemsLinks.map((link, subIndex) => (
                  <li key={subIndex}>
                    <Link to="/">{link}</Link>
                  </li>
                ))}
              </ul>
              <div className="download-app">
                <h2>{t("footer.downloads_app")}</h2>
                <div className="gap-2">
                  <img
                    src="/Assets/img/footer/App Store.png"
                    alt="download lucid app from app store"
                  />
                  <img
                    src="/Assets/img/footer/google play.png"
                    alt="download lucid app from play store"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 subscribe-section  col-md-12 mt-md-4 mt-4 mt-lg-0 ">
            <h2>{t("footer.subscribe_newsletter")}</h2>
            <p className="text-sm">{t("footer.we_believe")}</p>
            <div className="footer-newsletter-field-block">
              <form onSubmit={handleSubmit}>
                <input
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">{t("footer.subscribe")}</button>
              </form>
            </div>
            <div>
              <span className="logo-text">{t("footer.certified_by")}</span>
              <div className="img-under-foot">
                <img
                  className="d-none d-lg-block d-md-block"
                  style={{ width: 74 }}
                  src="/Assets/img/images33/Untitled-2 1.png"
                  alt="certified by companies"
                />
                <img
                  className="d-none d-lg-block d-md-block"
                  style={{ width: 74 }}
                  src="/Assets/img/images33/111 1.png"
                  alt="certified by companies"
                />
                <img
                  className="d-block d-lg-none d-md-none"
                  style={{ width: 45 }}
                  src="/Assets/img/images33/Untitled-2 1.png"
                  alt="certified by companies"
                />
                <img
                  className="d-block d-lg-none d-md-none"
                  style={{ width: 45 }}
                  src="/Assets/img/images33/111 1.png"
                  alt="certified by companies"
                />
                <img
                  className="d-none d-lg-block d-md-none  rounded-pill"
                  style={{ width: "70%" }}
                  src="/Assets/img/images33/CITRA jpg.jpg"
                  alt="certified by companies"
                />
                <img
                  className="d-block d-lg-none d-md-block rounded-pill"
                  style={{ maxWidth: 200 }}
                  src="/Assets/img/images33/CITRA jpg.jpg"
                  alt="certified by companies"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-0 mt-lg-4 mt-0 mt-md-4 align-items-center">
          <div className="col-12 col-lg-7 col-md-8 p-2  ">
            <p className="mb-4 mt-md-0 mt-lg-0  mt-4 text-lg-start text-md-start text-sm-center">
              {t("footer.copyright")}
            </p>
          </div>
          <div className=" d-none d-lg-block d-md-block col-12 col-md-4 col-lg-5 px-5 px-md-0 px-lg-0 col-lg-3 footer-bottom-section-col-2">
            <ul>
              {footerBottomlinks.map((link, subIndex) => (
                <li key={subIndex}>
                  <Link to="/">{link}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Toaster
          containerStyle={{
            overflow: "hidden",
            bottom: 400,
          }}
          position="bottom-center"
        />
      </div>
    </footer>
  );
};

export default Footer;
