import "./Components/Pages/Responsive.css";
import "./App.css";
import Navbar from "./Components/Common/Navbar/Navbar";
import Home from "./Components/Pages/Home/Home";
import Pricing from "./Components/Pages/Pricing/Pricing";
import Seo from "./Components/Pages/LandingPage/SeoServices/Seo";
import Footer from "./Components/Common/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GraphicDesign from "./Components/Pages/ServicePages/GraphicDesign/GraphicDesign";
import SWDev from "./Components/Pages/ServicePages/SWDev/SWDev";
import SWTesting from "./Components/Pages/ServicePages/SWTesting/SWTesting";
import SocialMediaMarketing from "./Components/Pages/ServicePages/SocialMediaMarketing/SocialMediaMarketing";
import Solution from "./Components/Pages/Solution/Solution";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service/seo" element={<Seo />} />
          <Route path="/service/graphic-design" element={<GraphicDesign />} />
          <Route path="/service/software-development" element={<SWDev />} />
          <Route path="/service/software-testing" element={<SWTesting />} />
          <Route
            path="/service/social-media-marketing"
            element={<SocialMediaMarketing />}
          />
          <Route path="/solutions/ecommerce" element={<Solution />} />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
