import React from "react";
import Bannar from "./Bannar/Bannar";
import PricingTable from "./PricingTable/Pricingtable";
import RtoLVector from "../../UI-Style/RtoLVector";
import { useTranslation } from "react-i18next";
import LtoR from "../../UI-Style/LtoRVector";

const Pricing = () => {
  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";
  const pricingData = t("pricing.PricingTable", {
    returnObjects: true,
  });
  return (
    <div>
      <Bannar />
      <RtoLVector />
      <PricingTable pricePackageData={pricingData} />
      <div className="up-toSpace">
        <LtoR />
      </div>
    </div>
  );
};

export default Pricing;
