import React from "react";
import "./Landingbannar.css";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { BiLogoPlayStore } from "react-icons/bi";
import "../../../Pages/Responsive.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const TechAboutBanner = () => {
  const { t, i18n, ready } = useTranslation();

  if (!ready) return "loading translations...";
  const landing_page = t("landing_page", { returnObjects: true });
  return (
    <section className="landing-bg-img">
      <div className="tech-about-banner">
        <div className="row">
          <div className="col-lg-5 col-md-12 col-12">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                duration: 1,
              }}
              className="land-content"
            >
              <h1>
                {t("landing_page.LandingBannar.title.firstPart")} <br />{" "}
                {t("landing_page.LandingBannar.title.secondPart")}
                <span> {t("landing_page.LandingBannar.title.thirdPart")}</span>
              </h1>
              <p>{t("landing_page.LandingBannar.paragraph1")}</p>
              <p>{t("landing_page.LandingBannar.paragraph1")}</p>
              <button>{t("landing_page.LandingBannar.get_started_btn")}</button>
            </motion.div>

            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
              }}
            >
              <div className="d-flex align-items-center gap-15">
                <div className="icons-banner">
                  <a href="/">
                    <FaInstagram />
                  </a>
                </div>
                <div className="icons-banner">
                  <a href="/">
                    <img
                      src="/Assets/img/Seo/Vector.png"
                      alt="find lucid on app store"
                    />
                  </a>
                </div>
                <div className="icons-banner">
                  <a href="/">
                    <BiLogoPlayStore />
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: "spring", stiffness: 60 },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 1,
            }}
            className="col-lg-7 col-md-12 col-12"
          >
            <img
              src="/Assets/img/Seo/about-banner.png"
              alt="SEO Services in Kuwait - Improve Search Engine Rankings with Lucid"
              className="banner-image mt-5 mt-lg-0 mt-md-0"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default TechAboutBanner;
