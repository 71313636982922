import React from "react";
import "./Solutions.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Solutions = () => {
  const { t, i18n, ready } = useTranslation();
  const solutions = t("solutions", { returnObjects: true });

  if (!ready) return "loading translations...";

  const serviceData = t("serviceData", { returnObjects: true });
  return (
    <div className="solutions">
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="cards-content">
              <motion.button
                initial={{ y: -100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                }}
              >
                <div>{t("solutionsSection.title")}</div>
              </motion.button>
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                  duration: 1,
                }}
              >
                <div>
                  <h2>{t("solutionsSection.subtitle")}</h2>
                  <p>{t("solutionsSection.description")}</p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="row solution-section">
          <div className="row">
            {solutions.map((solution, index) => (
              <motion.div
                className="col-lg-4 col-md-6 col-sm-12 mb-4"
                key={index}
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: "spring", stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: "easeIn",
                  // duration: 1,
                }}
              >
                {/* <div > */}
                <div className="solution-card position-relative shadow-sm">
                  <img
                    src={solution.image}
                    alt={solution.img_alt}
                    className="img-fluid rounded"
                  />
                  <div className="card-content d-flex align-items-center justify-content-between">
                    <h3>{solution.title}</h3>
                    <button className="solution-button btn p-0">
                      <img
                        src="/Assets/img/soulution/Button.png"
                        alt="Button Icon"
                        className="img-fluid"
                      />
                    </button>
                  </div>
                </div>
                {/* </div> */}
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
